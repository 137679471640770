import {STEPS_NUMBERS} from './../../../../shared/constants/AppConst';
import {createSlice} from '@reduxjs/toolkit';
import {CaregiverDetailsDto} from 'src/shared/services/caregivers/dto/details-caregiver-dto';
import {CertificateDetailsDto} from 'src/shared/services/cms/certificate/dto/details-certificate-dto';
import {LanguageDetailsDto} from 'src/shared/services/cms/languages/dto/language-details-dto';
import {OtherDetailsDto} from 'src/shared/services/other-details/dto/other-details-details-dto';
import {QualificationDetailsDto} from 'src/shared/services/qualifications/dto/qualification-details-dto';
import {SkillDetailsDto} from 'src/shared/services/skills/dto/skill-details-dto';

interface CaregiversInitialState {
  caregiverDetails: CaregiverDetailsDto | undefined;
  currentStepCaregiver: number;
  isQualificationsListLoading: boolean;
  qualificationsList: Array<QualificationDetailsDto>;
  isSkillsListLoading: boolean;
  skillsList: Array<SkillDetailsDto>;
  isCertificationsListLoading: boolean;
  certificationsList: Array<CertificateDetailsDto>;
  isOtherDetailsListLoading: boolean;
  otherDetailsList: Array<OtherDetailsDto>;
  isLanguagesListLoading: boolean;
  languagesList: Array<LanguageDetailsDto>;
}

const initialState: CaregiversInitialState = {
  caregiverDetails: undefined,
  currentStepCaregiver: STEPS_NUMBERS.One,
  isQualificationsListLoading: false,
  qualificationsList: [],
  isSkillsListLoading: false,
  skillsList: [],
  isCertificationsListLoading: false,
  certificationsList: [],
  isOtherDetailsListLoading: false,
  otherDetailsList: [],
  isLanguagesListLoading: false,
  languagesList: [],
};

const caregiversSlice = createSlice({
  name: 'caregivers',
  initialState: initialState,
  reducers: {
    setCaregiverDetails: (state, action) => {
      state.caregiverDetails = action.payload;
    },
    setCurrentStepCaregiver: (state, action) => {
      state.currentStepCaregiver = action.payload;
    },
    setIsQualificationsListCaregiverLoading: (state, action) => {
      state.isQualificationsListLoading = action.payload;
    },
    setQualificationListCaregiver: (state, action) => {
      state.qualificationsList = action.payload;
    },
    setIsSkillsListCaregiverLoading: (state, action) => {
      state.isSkillsListLoading = action.payload;
    },
    setSkillsListCaregiver: (state, action) => {
      state.skillsList = action.payload;
    },
    setIsCertificationsListCaregiverLoading: (state, action) => {
      state.isCertificationsListLoading = action.payload;
    },
    setCertificationsListCaregiver: (state, action) => {
      state.certificationsList = action.payload;
    },
    setIsOtherDetailsListCaregiverLoading: (state, action) => {
      state.isOtherDetailsListLoading = action.payload;
    },
    setOtherDetailsListCaregiver: (state, action) => {
      state.otherDetailsList = action.payload;
    },
    setIsLanguagesListCaregiverLoading: (state, action) => {
      state.isLanguagesListLoading = action.payload;
    },
    setLanguagesListCaregiver: (state, action) => {
      state.languagesList = action.payload;
    },
  },
});

export const {
  setCaregiverDetails,
  setCurrentStepCaregiver,
  setIsQualificationsListCaregiverLoading,
  setQualificationListCaregiver,
  setIsSkillsListCaregiverLoading,
  setSkillsListCaregiver,
  setIsCertificationsListCaregiverLoading,
  setCertificationsListCaregiver,
  setIsOtherDetailsListCaregiverLoading,
  setOtherDetailsListCaregiver,
  setIsLanguagesListCaregiverLoading,
  setLanguagesListCaregiver,
} = caregiversSlice.actions;

export default caregiversSlice.reducer;

export const authRole = {
  admin: ['user', 'admin'],
  user: ['user'],
};

export const PAGES_URL = {
  CREATE_CASE: '/cases/create',
  UPDATE_CASE: '/cases/update',
};

export const HidFooterInPages = [PAGES_URL.CREATE_CASE, PAGES_URL.UPDATE_CASE];

export const RoutePermittedRole = {
  admin: 'admin',
  user: 'user',
};

export const ThemeStyle = {
  MODERN: 'modern',
  STANDARD: 'standard',
};

export const ThemeMode = {
  LIGHT: 'light',
  SEMI_DARK: 'semi-dark',
  DARK: 'dark',
};

export const LayoutType = {
  FULL_WIDTH: 'full-width',
  BOXED: 'boxed',
  FRAMED: 'framed',
};

export const MenuStyle = {
  DEFAULT: 'default',
  STANDARD: 'standard',
  ROUNDED: 'rounded',
  ROUNDED_REVERSE: 'rounded-reverse',
  CURVED_MENU: 'curved-menu',
};

export const NavStyle = {
  DEFAULT: 'default',
  MINI: 'mini',
  MINI_SIDEBAR_TOGGLE: 'mini-sidebar-toggle',
  STANDARD: 'standard',
  HEADER_USER: 'user-header',
  HEADER_USER_MINI: 'user-mini-header',
  DRAWER: 'drawer',
  BIT_BUCKET: 'bit-bucket',
  H_DEFAULT: 'h-default',
  HOR_HEADER_FIXED: 'hor-header-fixed',
  HOR_DARK_LAYOUT: 'hor-dark-layout',
};
export const FooterType = {
  FIXED: 'fixed',
  FLUID: 'fluid',
};
export const ThemeDirection = {
  RTL: 'rtl',
  LTR: 'ltr',
};

export enum DaysOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export enum NotificationTypeValue {
  UpdateBookingShift = 1,
  UpdateCaregiverPayroll = 2,
}

export const UploadFileType = {
  ProfileImage: 1,
  CareCategory: 2,
  CareSubCategory: 3,
  CareService: 4,
  AdditionalService: 5,
  Skill: 6,
  Certificate: 7,
  Qualification: 8,
  Language: 9,
  CareRecipient: 10,
  Client: 11,
  BookingPayment: 12,
  CaregiverDocuments: 13,
};

export const ScheduleType = {
  regular: 1,
  variable: 2,
};

export const CaseStatus = {
  Canceled: 0,
  Draft: 1,
  Submited: 2,
};

export const CaseFileType = {
  ID_Passport: 1,
  UnderTaking: 2,
  DoctorPrescription: 3,
  MedicalReport: 4,
  Other: 5,
};

export const PaymentMethod = {
  OnlinePayment: 1,
  CashToFAN: 2,
  CashToCaregiver: 3,
  BobFinance: 4,
  BankOfBeirut: 5,
  OMT: 6,
};

export const NumberOfHoursOptions = {
  oneHourShift: 1,
  twoHourShift: 2,
  threeHourShift: 3,
  fourHourShift: 4,
  fiveHourShift: 5,
  sixHourShift: 6,
  sevenHourShift: 7,
  eightHourShift: 8,
  nineHourShift: 9,
  tenHourShift: 10,
  elevenHourShift: 11,
  twelveHourShift: 12,
  eightTeenHourShift: 18,
  twentyFourHourShift: 24,
};

export const MarkupType = {
  Consecutive: 1,
  NonConsecutive: 2,
};

export const BookingStatus = {
  draft: 1,
  confirmed: 2,
  inProgress: 3,
  completed: 4,
  cancelled: 5,
  onHold: 6,
  paused: 7,
};

export const BookingShiftStatus = {
  scheduled: 1,
  unConfirmed: 2,
  confirmed: 3,
  canceled: 4,
  // paused: 5,
  // onHold: 6,
};

export const BookingMarkupReference = {
  planned: 1,
  // actual: 2,
  manually: 3,
};

export const BookingShiftFeesReference = {
  planned: 1,
  actual: 2,
  override: 3,
};

export const PaymentStatus = {
  Unpaid: 1,
  Paid: 2,
};

export const PaymentMethodForPayroll = {
  BobFinance: 1,
  Cash: 2,
  Other: 3,
};

export const PaymentCaregiverPayrollStatus = {
  pending: 1,
  paid: 2,
};

export const Degree = {
  None: 1,
  Brevet: 2,
  HighSchool: 3,
  Bachelors: 4,
  Master: 5,
  PhD: 6,
};

export const ProficiencyLevel = {
  NoProficiency: 1,
  Beginner: 2,
  Intermediate: 3,
  Advanced: 4,
  Native: 5,
};

export const CaregiverFileType = {
  idPassport: 1,
  underTaking: 2,
  doctorPrescription: 3,
  medicalReport: 4,
  other: 5,
  criminalRecord: 6,
  workLicense: 7,
  resume: 8,
  drivingLicense: 9,
  membershipCard: 10,
};

export const Nationalities = {
  // none: 0,
  lebanese: 1,
  syrian: 2,
  palestinian: 3,
  other: 4,
};

export const CaregiverRelationship = {
  family: 1,
  friend: 2,
  manager: 3,
  colleague: 4,
  previousClient: 5,
  other: 6,
};

export const Gender = {
  male: 1,
  female: 2,
  noPreference: 3,
};

export const ClientReachedOut = {
  directCall: 1,
  whatsApp: 2,
  filledForm: 3,
  socialMedia: 4,
  signedUp: 5,
  email: 6,
};
export const ClientContactedType = {
  directCall: 1,
  whatsApp: 2,
  email: 3,
};

export const ClientContacted = {
  no: 0,
  yes: 1,
};

export const YesOrNo = {
  no: 0,
  yes: 1,
};
